import React, { useState } from 'react';
import { Avatar, Box, Button, Typography } from '@mui/material';
import DealOptionsMenu from './DealOptionsMenu';
import { newFormattedLocalCustom } from '../../common/Utilities';
import { GamifyIcon } from '../../components/GamifyIcon';
import { usePermissions } from '../../context/PermissionsContext/PermissionsContext';
import { PermissionKeys } from '../../context/PermissionsContext/permissionTypes';

export default function DealCard(props: any) {
  const {
    deal,
    isOnKanban,
    payEngineStatus,
    selectedDealId,
    handleDealSelection,
    setAddDealOpen,
    handleOpenOwnerDealStageModal,
    handleOpenChargeModal,
    stageId,
    dealStage,
    handleDeleteDeal,
    page,
    userId,
    categoryId,
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { hasPermission } = usePermissions();

  // create an array of owner ids and filter out any falsey values
  const ownersIds = [deal?.setter?.id, deal?.closer?.id].filter(Boolean);

  const showEdit = hasPermission(PermissionKeys.Sales_UpdateDeal, ownersIds);
  const showDelete = hasPermission(PermissionKeys.Sales_DeleteDeal, ownersIds);
  // const showSchedule = hasPermission(
  //   PermissionKeys.Scheduling_BookAppointment,
  //   ownersIds
  // );
  const showMenu = showEdit || showDelete; /* || showSchedule; */

  const handleMenuOpen = (e: any) => {
    setAnchorEl(e.currentTarget);
    setAddDealOpen(false);
    // setSortOpen(false);
  };

  return (
    <Box
      style={{
        width: isOnKanban ? 450 : '100%',
        backgroundColor: 'white',
        padding: 8,
        marginBottom: 12,
        borderRadius: 8,
        border:
          deal.id === selectedDealId
            ? '1px solid #FF6A00'
            : '1px solid #D0D2D8',
      }}
      sx={{ cursor: 'pointer' }}
      onClick={(e) => handleDealSelection(e, deal.id, deal)}
    >
      <Box className={'deal-card-name-stage-section'}>
        <Typography className={'deal-card-full-name-text'}>
          {deal.name}
        </Typography>
        <Box className={'deal-card-name-stage-section-right'}>
          {!isOnKanban && (
            <Box
              style={{
                width: 'fit-content',
                minWidth: 'fit-content',
                backgroundColor: '#FAC8B2',
                borderRadius: 1000,
              }}
            >
              <Typography className={'deal-card-stage-text'}>
                {dealStage}
              </Typography>
            </Box>
          )}
          {showMenu && (
            <>
              <Button
                disableRipple={true}
                // onClick={handleDealOptionsClick}
                onClick={(e) => handleMenuOpen(e)}
                className={'ellipsis-button'}
              >
                <GamifyIcon
                  icon={'ellipsis'}
                  color={'white'}
                  backgroundColor={''}
                  width={17}
                  height={4}
                />
              </Button>
              <DealOptionsMenu
                props={{
                  dealId: deal.id,
                  anchorEl,
                  setAnchorEl,
                  payEngineStatus,
                  handleOpenOwnerDealStageModal,
                  handleOpenChargeModal,
                  stageId,
                  handleDeleteDeal,
                  page,
                  userId,
                  categoryId,
                  ourDeal: deal,
                  setter: deal.setter,
                  closer: deal.closer,
                }}
              ></DealOptionsMenu>
            </>
          )}
        </Box>
      </Box>
      {deal.attrs?.length > 0 ? (
        // @ts-ignore
        deal.attrs.map((attr, i) => (
          <Box key={i}>
            <Typography className={'deal-card-attribute-text'}>
              {typeof attr === 'string' ? attr : attr.S}
            </Typography>
          </Box>
        ))
      ) : (
        <></>
      )}
      <Box className={'deal-card-owner-section'}>
        <Box className={'deal-card-owner-section-left'}>
          <Box style={{ display: 'flex' }}>
            <Typography
              style={{
                fontSize: '14px',
                fontWeight: 500,
                marginRight: 4,
              }}
            >
              Setter:
            </Typography>
            {deal.setter?.profilePicture ? (
              <Avatar
                className={'deal-card-owner-profile-picture'}
                src={deal.setter?.profilePicture}
              />
            ) : (
              <Avatar className={'deal-card-owner-profile-picture'}>
                <Typography style={{ fontSize: 12, fontWeight: 600 }}>
                  {deal.setter?.initials}
                </Typography>
              </Avatar>
            )}
            <Typography className={'deal-card-owner-text'}>
              {deal.setter?.name}
            </Typography>
          </Box>
          <Box style={{ display: 'flex', marginTop: 12 }}>
            <Typography
              style={{
                fontSize: '14px',
                fontWeight: 500,
                marginRight: 4,
              }}
            >
              Closer:
            </Typography>
            {deal.closer ? (
              deal.closer.profilePicture ? (
                <Avatar
                  className={'deal-card-owner-profile-picture'}
                  src={deal.closer.profilePicture}
                />
              ) : (
                <Avatar className={'deal-card-owner-profile-picture'}>
                  <Typography style={{ fontSize: 12, fontWeight: 600 }}>
                    {deal.closer.initials}
                  </Typography>
                </Avatar>
              )
            ) : (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#bdbdbd',
                  width: 24,
                  height: 24,
                  borderRadius: 1000,
                  marginRight: 8,
                }}
              >
                <GamifyIcon
                  icon={'user'}
                  color={'#323232'}
                  backgroundColor={''}
                  width={'14'}
                  height={'16'}
                ></GamifyIcon>
              </Box>
            )}
            <Typography className={'deal-card-owner-text'}>
              {deal.closer ? deal.closer.name : 'Unassigned'}
            </Typography>
          </Box>
        </Box>
        <Box className={'deal-card-owner-section-right'}>
          <Typography className="deal-card-updated-at-text">
            {newFormattedLocalCustom(deal.updatedAt, 'M/D/YY h:mm a') !==
            'Invalid date'
              ? newFormattedLocalCustom(deal.updatedAt, 'M/D/YY h:mm a')
              : deal.updatedAt}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
