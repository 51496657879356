import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import CreditCardForm from './CreditCardForm.view';
import ACHForm from './ACHForm.view';

interface ChargesProps {
  deal: any;
  setShowChargeModal: any;
}

export default function Charges(props: ChargesProps) {
  const { deal, setShowChargeModal } = props;
  const [activeTab, setActiveTab] = useState<string>('Credit Card');

  const chargesTabs = [
    {
      label: 'Credit Card',
      val: 'Credit Card',
    },
    {
      label: 'ACH',
      val: 'ACH',
    },
  ];

  const handleNavigate = (val: string) => {
    setActiveTab(val);
  };

  return (
    <>
      <Box
        style={{
          width: '100%',
          backgroundColor: 'white',
          //   position: 'fixed',
          //   zIndex: 100,
          //   top: 90,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          borderBottom: '1px solid #F0F0F3',
        }}
      >
        <Box
          style={{
            display: 'flex',
            height: 48,
            maxHeight: 48,
            border: '1px solid #F0F0F3',
            borderRadius: 8,
            // marginTop: 24,
            marginBottom: 24,
          }}
        >
          {chargesTabs.map((chargesTab, i) => (
            <Box
              key={i}
              className={'charges-tab'}
              sx={{ cursor: 'pointer' }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 'fit-content',
                padding: 12,
                borderRadius:
                  i === 0
                    ? '8px 0px 0px 8px'
                    : i === chargesTabs.length - 1
                    ? '0px 8px 8px 0px'
                    : 0,
                backgroundColor:
                  activeTab === chargesTab.val ? '#FF6A00' : 'white',
              }}
              onClick={() => handleNavigate(chargesTab.val)}
            >
              <Typography
                style={{
                  fontSize: 16,
                  color: activeTab === chargesTab.val ? 'white' : '#7C7C7C',
                  fontWeight: activeTab === chargesTab.val ? 600 : 400,
                }}
              >
                {chargesTab.label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        style={{
          width: '100%',
          //   height: 'calc(100% - 90px)',
          overflowY: 'auto',
        }}
      >
        {activeTab === 'Credit Card' ? (
          <CreditCardForm
            deal={deal}
            setShowChargeModal={setShowChargeModal}
          ></CreditCardForm>
        ) : (
          <ACHForm
            deal={deal}
            setShowChargeModal={setShowChargeModal}
          ></ACHForm>
        )}
      </Box>
    </>
  );
}
