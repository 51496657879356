import React, { SetStateAction, useState, Dispatch } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { API } from 'aws-amplify';
import * as mutations from '../../../../graphql/mutations';
import { GamifyIcon } from '../../../components/GamifyIcon';
import { GamifyToast } from '../../../common/CustomToasts';
import { FormInput } from '../../../common/FormInputs';

interface MerchantFormProps {
  setMerchantId: Dispatch<SetStateAction<string>>;
}

export default function CreatePEMerchantForm(props: MerchantFormProps) {
  const { setMerchantId } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const newMerchant = {
    external_id: '',
    name: '',
    email: '',
  };

  const [initialValues, setInitialValues] = useState(newMerchant);

  // This form doesn't need to update the initialValues of the form because the user only fills it out once for the whole org. This log should be unreachable, just here to get by eslint
  if (Object.keys(initialValues).length > 3) {
    console.log(setInitialValues);
  }

  const merchantValidationSchema = yup.object().shape({
    name: yup
      .string()
      .label('Name')
      .required()
      .min(2, 'Your name is too short.  Please enter at least 2 characters')
      .max(32, 'Your name is too long.  Please enter less than 32 characters'),
    email: yup
      .string()
      .label('Email')
      .required()
      .email('Please enter a valid email addresss'),
  });

  async function doSubmit(values: any, actions: any) {
    // console.log('doSubmit args (values, actions): ', values, actions);
    actions.setSubmitting(true);
    setIsLoading(true);

    const newMerchantId = await createMerchant(values);

    if (newMerchantId) {
      setTimeout(() => {
        //  Set the new merchant id so that the onboarding form renders instead of this form
        setMerchantId(newMerchantId);

        // Update the global object so this persists after leaving the Payments tab and returning
        // @ts-ignore
        if (global.appSettings) {
          // @ts-ignore
          global.appSettings.payEngineMerchantId = newMerchantId;
          // @ts-ignore
          global.appSettings.payEngineMerchantStatus = 'editing';
        }

        GamifyToast.success(
          `Merchant profile created successfully. You may now fill in the PayEngine onboarding form.`
        );
      }, 500);
    }

    setTimeout(() => {
      actions.resetForm();
      setHasSubmitted(false);
      actions.setSubmitting(false);
      setIsLoading(false);
    }, 500);
  }

  async function createMerchant(values: any) {
    try {
      const merchant = {
        // @ts-ignore
        orgID: global.me.orgID,
        name: values.name,
        email: values.email,
      };

      const createMerchantRes = await API.graphql({
        // @ts-ignore
        query: mutations.createPayEngineMerchant,
        variables: {
          request: JSON.stringify({
            ...merchant,
          }),
        },
      });

      //   @ts-ignore
      const result = JSON.parse(createMerchantRes.data.createPayEngineMerchant);

      // Return the merchantId and then set in the doSubmit function to cause the re-render that shows the onboarding form instead of this form
      const newMerchantId = JSON.parse(result?.body)?.data?.id;

      // Check if the Lambda responded with an error
      if (result.statusCode && result.statusCode !== 200) {
        console.error('Lambda returned an error:', result.body);
        GamifyToast.error('There was an error creating the merchant profile');
        return false;
      }

      return newMerchantId;
      // return true;
    } catch (err) {
      console.error('createMerchant(): error creating merchant :', err);
      GamifyToast.error('There was an error creating the merchant profile');

      return false;
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validateOnMount={true} // Need this to validate before first submit attempt
      onSubmit={(values, actions) => {
        doSubmit(values, actions);
      }}
      validationSchema={merchantValidationSchema}
    >
      {(formikProps) => (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            zIndex: 1,
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
          }}
        >
          <Box
            style={{
              display: 'flex',
              position: 'absolute',
              zIndex: -1,
              backgroundColor: '#f0f0f3',
              height: 346,
              width: '100%',
            }}
          />
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              margin: '124px 60px 0px',
            }}
          >
            <Box style={{ width: 708 }}>
              <Typography
                style={{
                  color: '#222428',
                  fontSize: 24,
                  fontWeight: 700,
                  textAlign: 'left',
                }}
              >
                {`Create PayEngine Merchant Profile`}
              </Typography>
              <Typography
                style={{
                  color: '#222428',
                  fontSize: 16,
                  fontWeight: 500,
                  textAlign: 'left',
                }}
              >
                {`In order to enable payments, you must create a merchant profile with PayEngine for your org with this form and then fill in the onboarding form.`}
              </Typography>
            </Box>
          </Box>

          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: 708,
              marginTop: 24,
              paddingBottom: 60,
            }}
          >
            <AboutForm
              formikProps={formikProps}
              hasSubmitted={hasSubmitted}
            ></AboutForm>

            <Box className={'save-team-button-container'}>
              <Button
                className={'save-team-button'}
                onClick={() => {
                  setHasSubmitted(true);

                  if (!formikProps.isValid) {
                    GamifyToast.error('Please fix all errors and try again');
                  }

                  formikProps.handleSubmit();
                }}
                disableRipple={true}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress
                    style={{ color: 'white', height: 24, width: 24 }}
                  ></CircularProgress>
                ) : (
                  <Typography className={'save-team-button-text'}>
                    {'Create profile'}
                  </Typography>
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Formik>
  );
}

function AboutForm({ formikProps, hasSubmitted }: any) {
  console.log('AboutForm formikProps: ', formikProps);

  return (
    <Box
      style={{
        backgroundColor: '#F0F0F3',
        borderRadius: 8,
        paddingBottom: 0,
        marginBottom: 24,
      }}
    >
      <Box
        style={{
          width: '100%',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          backgroundColor: '#D0D2D8',
          padding: 16,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography style={{ fontSize: 18, color: '#222428', fontWeight: 600 }}>
          About
        </Typography>
        <GamifyIcon icon={'about'} color="#323232" />
      </Box>
      <Box style={{ padding: '24px 16px 8px 16px' }}>
        <FormInput
          inputName={'name'}
          formikProps={formikProps}
          hasSubmitted={hasSubmitted}
          required={true}
        ></FormInput>
        <FormInput
          inputName={'email'}
          formikProps={formikProps}
          hasSubmitted={hasSubmitted}
          required={true}
        ></FormInput>
      </Box>
    </Box>
  );
}
