import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
// import { Box, Button, Typography } from '@mui/material';
// import { CustomMenu, CustomMenuItem } from '../teams/StyledComponent';
import { CustomMenu, CustomMenuItem } from '../../common/StyledComponents';
import { API, graphqlOperation } from 'aws-amplify';
// import { updateDeal } from '../../../graphql/mutations';
import * as mutations from '../../../graphql/mutations';
import { GamifyToast } from '../../common/CustomToasts';
import DynamicConfirmModal from '../../common/DynamicConfirmModal';
import { usePermissions } from '../../context/PermissionsContext/PermissionsContext';
import { PermissionKeys } from '../../context/PermissionsContext/permissionTypes';
import featureFlag from '../../common/featureFlag';
// import DealFormModal from './DealFormModal';

export default function DealOptionsMenu({ props }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // console.log('deal options menu props: ', props);
  const {
    dealId,
    anchorEl,
    setAnchorEl,
    payEngineStatus,
    handleOpenOwnerDealStageModal,
    handleOpenChargeModal,
    stageId,
    handleDeleteDeal,
    setter,
    closer,
  } = props;

  const isOnKanban = location.pathname.includes('board');

  const { hasPermission, checkPermission } = usePermissions();

  // create an array of owner ids and filter out any falsey values
  const ownersIds = [setter?.id, closer?.id].filter(Boolean);

  const showEdit = hasPermission(PermissionKeys.Sales_UpdateDeal, ownersIds);
  const showDelete = hasPermission(PermissionKeys.Sales_DeleteDeal, ownersIds);
  // const showSchedule = hasPermission(
  //   PermissionKeys.Scheduling_BookAppointment,
  //   ownersIds
  // );
  const canCharge = checkPermission(PermissionKeys.Sales_Charge);

  // console.log('deal options dealID: ', dealID);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
    setShowConfirmDelete(true);
  };

  // const handleCancelDeleteClick = (e) => {
  //   e.stopPropagation();
  //   setShowConfirmDelete(false);
  // };

  const handleConfirmDeleteClick = async (dealID) => {
    // console.log('deal options handleConfirmDeleteClick dealID: ', dealID);
    setIsSaving(true);
    try {
      //
      //  Delete the deal
      //
      await API.graphql(
        graphqlOperation(mutations.deleteDealObject, {
          id: dealID,
        })
      );
      // await API.graphql(
      //   graphqlOperation(updateDeal, {
      //     input: { id: dealID, isDeleted: true },
      //   })
      // );

      setShowConfirmDelete(false);
      setIsSaving(false);
      GamifyToast.success(`Deal deleted successfully`);

      // reroute to /pipeline/board or /pipeline/list from the /pipeline/<dealID> page of the deleted deal
      if (isOnKanban) {
        navigate('/pipeline/board');
        handleDeleteDeal(dealID, stageId);
      } else {
        navigate('/pipeline/list');
        handleDeleteDeal(dealID);
      }
    } catch (err) {
      setShowConfirmDelete(false);
      setIsSaving(false);
      GamifyToast.error(`There was an error deleting your deal`);
      console.error('deal options handleConfirmDeleteClick error: ', err);
    }
  };

  return (
    <>
      {/* NOTE:  DynamicConfirmModal replaced the custom Backdrop component commented out below */}
      <DynamicConfirmModal
        showDynamicConfirmModal={showConfirmDelete}
        setShowDynamicConfirmModal={setShowConfirmDelete}
        zIndex={100002}
        title={`Are you sure you want to delete this deal?`}
        subtitle={`This action cannot be undone.`}
        leftAlignText={true}
        confirmButtonColor={'#E35050'}
        isSaving={isSaving}
        onCancel={() => {
          setShowConfirmDelete(false);
        }}
        onConfirm={() => {
          // console.log('onConfirm dealID: ', dealID);
          handleConfirmDeleteClick(dealId);
        }}
      ></DynamicConfirmModal>

      {/* NOTE: CustomMenu and CustomMenuItem components replaced the commented out code below */}
      <CustomMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(e) => {
          e.stopPropagation();
          handleMenuClose();
        }}
      >
        {showEdit && (
          <CustomMenuItem
            sx={{
              background: 'white',
              color: 'black',
              paddingTop: '9px',
              paddingBottom: '9px',
            }}
            onClick={(e) => {
              e.stopPropagation();
              // console.log('dealID edit click: ', dealId);
              // NOTE: This is for if DealFormPage is used on its own at /edit/<dealID>, but it is now used in a modal so no navigation is needed
              // setShowEditDealFormModal(true);
              navigate(`/pipeline/edit/${dealId}`, {
                // TO DO: add in other props for DealFormPage, see CreateDealScreen component in lyferize MyDealScreen.tsx for reference
                state: {
                  isOnKanban,
                },
              });
            }}
            disableRipple={true}
          >
            Edit
          </CustomMenuItem>
        )}
        {showEdit && (
          <CustomMenuItem
            sx={{
              background: 'white',
              color: 'black',
              paddingTop: '9px',
              paddingBottom: '9px',
            }}
            onClick={(e) => {
              e.stopPropagation();
              // setShowOwnerDealStageModal(true);
              handleOpenOwnerDealStageModal();
              setAnchorEl(null);
            }}
            disableRipple={true}
          >
            Change Stage
          </CustomMenuItem>
        )}
        {/* NOTE: Leave Schedule option out until calendar/scheduling for web is built */}
        {/* {showSchedule && <CustomMenuItem
          sx={{
            background: 'white',
            color: 'black',
            paddingTop: '9px',
            paddingBottom: '9px',
          }}
          onClick={(e) => {
            e.stopPropagation();
            console.log('Schedule click');
          }}
          disableRipple={true}
        >
          Schedule
        </CustomMenuItem>} */}
        {payEngineStatus === 'active' &&
          featureFlag('payments') &&
          canCharge && (
            <CustomMenuItem
              sx={{
                background: 'white',
                color: 'black',
                paddingTop: '9px',
                paddingBottom: '9px',
              }}
              onClick={(e) => {
                e.stopPropagation();

                handleOpenChargeModal();
                setAnchorEl(null);
              }}
              disableRipple={true}
            >
              Charge
            </CustomMenuItem>
          )}
        {showDelete && (
          <CustomMenuItem
            sx={{
              background: 'white',
              color: 'red',
              paddingTop: '9px',
              paddingBottom: '9px',
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteClick(e);
            }}
            disableRipple={true}
          >
            Delete
          </CustomMenuItem>
        )}
      </CustomMenu>
    </>
  );
}
