import React, { useState, useEffect, useRef } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router';
import { API, graphqlOperation } from 'aws-amplify';
import gql from 'graphql-tag';
import * as queries from '../../../graphql/queries';
import * as mutations from '../../../graphql/mutations';
import { Box, Typography } from '@mui/material';
import { GamifyIcon } from '../../components/GamifyIcon';
import { SideBarTab } from './PaymentsSideBar.types';
import CreatePEMerchantForm from './paymentsForms/CreatePEMerchantForm.view';
import { loadPayengine } from 'payengine';
import { PE_CONFIG } from './utils/config';
import { GamifyToast } from '../../common/CustomToasts';
import featureFlag from '../../common/featureFlag';
import { PermissionKeys } from '../../context/PermissionsContext/permissionTypes';
import { usePermissions } from '../../context/PermissionsContext/PermissionsContext';

export default function PaymentsSideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { checkPermission } = usePermissions();
  const canOnboard = checkPermission(PermissionKeys.Sales_OnboardPayEngine);
  const viewPayments = checkPermission(PermissionKeys.Sales_ViewPayments);

  useEffect(() => {
    if (!(featureFlag('payments') && viewPayments)) {
      navigate('/feed', { replace: true });
    }
  }, [navigate]);

  const [merchantId, setMerchantId] = useState<string>(
    // @ts-ignore
    global.appSettings?.payEngineMerchantId || ''
  );

  const [payEngineStatus, setPayEngineStatus] = useState<string>(
    // @ts-ignore
    global.appSettings?.payEngineMerchantStatus || ''
  );
  const [hmacHash, setHmacHash] = useState<string>('');

  const payEngineRef = useRef<HTMLElement | null>(null);

  const [activeTab, setActiveTab] = useState<string>('/payments/transactions');

  // Get an HMAC hash using the PayEngine secret key using the createPayEngineHash lambda
  const generateHMAC = async (merchantID: string) => {
    try {
      const res = await API.graphql(
        graphqlOperation(mutations.createPayEngineHash, {
          request: JSON.stringify({
            merchantID,
          }),
        })
      );
      // @ts-ignore
      const parsedData = JSON.parse(res.data.createPayEngineHash);
      const hmac = parsedData.hmac;
      return hmac;
    } catch (err) {
      console.error(err);

      GamifyToast.error(`There was an error verifying PayEngine credentials.`);
    }
  };

  async function setUpHMAC(merchantID: string) {
    // Generate HMAC for the merchant ID
    const hmac = await generateHMAC(merchantID);

    // Set the generated HMAC state variable
    if (hmac) {
      setHmacHash(hmac);
    }
  }

  // Get the status of the PE merchant profile
  const getMerchantStatus = async (merchantID: string) => {
    try {
      const res = await API.graphql({
        // @ts-ignore
        query: queries.getPEOnboardingStatus,
        variables: {
          request: JSON.stringify({
            merchantID,
          }),
        },
      });
      // @ts-ignore
      const parsedData = JSON.parse(res.data.getPEOnboardingStatus);
      const merchantStatus = parsedData.merchantStatus;
      return merchantStatus;
    } catch (err) {
      console.error(err);

      GamifyToast.error(
        `There was an error getting the PayEngine merchant profile status.`
      );
    }
  };

  const updateOrgPEStatus = async (merchantStatus: string) => {
    const myQuery = gql`
      query GetOrg($id: ID!) {
        getOrg(id: $id) {
          id
          configParms
        }
      }
    `;
    const res = await API.graphql({
      // @ts-ignore
      query: myQuery,
      // @ts-ignore
      variables: { id: global.me.orgID },
    });

    // @ts-ignore
    const org = res.data.getOrg;

    const newConfigParms = {
      ...JSON.parse(org.configParms),
      payEngineMerchantStatus: merchantStatus,
    };

    const myMutation = gql`
      mutation UpdateOrg($input: UpdateOrgInput!) {
        updateOrg(input: $input) {
          id
          configParms
        }
      }
    `;

    await API.graphql({
      // @ts-ignore
      query: myMutation,
      variables: {
        input: {
          id: org.id,
          configParms: JSON.stringify(newConfigParms),
        },
      },
    });
  };

  async function getPEOnboardingStatus(merchantId: string) {
    const merchantStatus = await getMerchantStatus(merchantId);

    // Set the payEngine status to merchantStatus state variable and update the org in dynamo to have that status to avoid future fetches once the merchant profile is active
    if (merchantStatus) {
      updateOrgPEStatus(merchantStatus);
      setPayEngineStatus(merchantStatus);
    }
  }

  useEffect(() => {
    // Get the HMAC hash and see if the merchant status needs to be checked
    if (merchantId) {
      setUpHMAC(merchantId);

      // NOTE: Only check the status if the merchant profile isn't already active
      if (payEngineStatus !== 'active') {
        getPEOnboardingStatus(merchantId);
      }
    }

    // Load Payengine on first render only
    loadPayengine({
      publicKey: PE_CONFIG.publicKey,
    }).then((pe) => {
      console.log(pe);
    });
  }, []);

  useEffect(() => {
    // NOTE: Need to reroute to payments/transactions when NavBar navigates to /payments
    if (location.pathname === '/payments') {
      navigate('/payments/transactions');
    }

    if (
      location.pathname === '/payments/transactions' &&
      activeTab !== '/payments/transactions'
    ) {
      setActiveTab('/payments/transactions');
    }

    if (location.pathname === '/payments/batch-settlements') {
      setActiveTab('/payments/batch-settlements');
    }

    if (location.pathname === '/payments/payouts') {
      setActiveTab('/payments/payouts');
    }

    if (location.pathname.includes('/payments/charges')) {
      setActiveTab('/payments/charges');
    }
  }, [location]);

  //   Set success and error listeners to the payEngineRef element (onboarding form)
  useEffect(() => {
    if (payEngineRef.current) {
      const handleStepChange = (e: any) => {
        console.log('Onboarding form step change:', e.detail);
      };

      const handleSubmit = (e: any) => {
        // if (errorsArr.length > 0) {
        //   setErrorsArr([]);
        // }

        console.log('Onboarding form submit success:', e.detail);
        GamifyToast.success(
          'PayEngine onboarding form successfully submitted.'
        );
      };

      const handleError = (e: any) => {
        console.error('Onboarding form error:', e.detail);

        GamifyToast.error(
          'There was an error submitting the onboarding form, please try again.'
        );
      };

      payEngineRef.current.addEventListener('stepChange', handleStepChange);
      payEngineRef.current.addEventListener('submitted', handleSubmit);
      payEngineRef.current.addEventListener('error', handleError);

      // Cleanup listeners on unmount
      return () => {
        payEngineRef.current?.removeEventListener(
          'stepChange',
          handleStepChange
        );
        payEngineRef.current?.removeEventListener('submitted', handleSubmit);
        payEngineRef.current?.removeEventListener('error', handleError);
      };
    }
  }, [payEngineRef.current]);

  const handleNavigate = (path: string) => {
    setActiveTab(path);
    navigate(path);
  };

  const sideBarTabs: SideBarTab[] = [
    {
      label: 'Transactions',
      val: 'transactions',
      icon: 'notes',
      path: '/payments/transactions',
    },
    {
      label: 'Batch Settlements',
      val: 'batch-settlements',
      icon: 'dollar',
      path: '/payments/batch-settlements',
    },
    {
      label: 'Payouts',
      val: 'payouts',
      icon: 'coins',
      path: '/payments/payouts',
    },
    {
      label: 'Merchant Statements',
      val: 'merchant-statements',
      icon: 'about',
      path: '/payments/merchant-statements',
    },
    {
      label: 'Merchant Reports',
      val: 'merchant-reports',
      icon: 'kws',
      path: '/payments/merchant-reports',
    },
  ];

  // Org needs to create a PE merchant profile before onboarding if merchantId is an empty string
  if (!merchantId) {
    return canOnboard ? (
      <CreatePEMerchantForm
        setMerchantId={setMerchantId}
      ></CreatePEMerchantForm>
    ) : (
      <Box
        style={{
          position: 'relative',
          // // top: 250,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100vw',
          boxSizing: 'content-box',
          paddingTop: 90,
          overflowY: 'auto',
        }}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '40vw',
            height: 'calc(100vh - 90px)',
          }}
        >
          <Typography style={{ fontSize: 36, textAlign: 'center' }}>
            {
              "Your organization needs to create a merchant profile with PayEngine for Payments still. Please have a user with a role that has the 'Onboard PayEngine' permission login, visit the 'Payments' tab, and fill in the Create PayEngine Merchant Profile form."
            }
          </Typography>
        </Box>
      </Box>
    );
  }

  // If there is a merchant id but the merchant status isn't active, render the onboarding web component
  if (merchantId && payEngineStatus !== 'active') {
    return (
      <Box
        style={{
          position: 'relative',
          // // top: 250,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100vw',
          boxSizing: 'content-box',
          paddingTop: 90,
          overflowY: 'auto',
        }}
      >
        {canOnboard ? (
          // @ts-ignore
          <pay-engine
            ref={payEngineRef}
            id={'onboarding'}
            merchant-id={merchantId}
            // merchant-id={'99d0b3a5-6bde-4105-a32c-670ceff37dec'}
            hash={hmacHash}
            // hash={
            //   'c98eae2932783ad1d81d16df03aee1f429383bf8d832e4273d334e5a99771c57'
            // }
            type={'boarding'}
          >
            {/* @ts-ignore */}
          </pay-engine>
        ) : (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '40vw',
              height: 'calc(100vh - 90px)',
            }}
          >
            <Typography style={{ fontSize: 36, textAlign: 'center' }}>
              {
                "Your organization needs to onboard for Payments still. Please have a user with a role that has the 'Onboard PayEngine' permission login, visit the 'Payments' tab, and fill in the onboarding form."
              }
            </Typography>
          </Box>
        )}
      </Box>
    );
  }

  // The side bar will only render if there is a merchant id and the merchant profile is 'active'
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        paddingTop: 90,
      }}
    >
      {/* <iframe
        width={'100%'}
        height={'100%'}
        src={'https://merchants.payengine.dev/signin'}
      ></iframe> */}
      <Box
        style={{
          height: '100%',
          width: 285,
          minWidth: 285,
          borderRight: '1px solid #7C7C7C',
        }}
      >
        <Box
          style={{
            marginTop: 50,
            marginLeft: 40,
          }}
        >
          <>
            <Typography style={{ fontSize: 20, fontWeight: 700 }}>
              Payments
            </Typography>
            {sideBarTabs.map((sideBarTab, i) => {
              return (
                <Box
                  key={i}
                  sx={{ cursor: 'pointer' }}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: 'fit-content',
                    marginTop: 32,
                  }}
                  onClick={() => handleNavigate(sideBarTab.path)}
                >
                  <Box
                    style={{
                      height: 24,
                      width: 24,
                      marginRight: 12,
                    }}
                  >
                    <GamifyIcon
                      icon={sideBarTab.icon}
                      color={
                        activeTab.includes(sideBarTab.val) ? 'black' : '#7C7C7C'
                      }
                      backgroundColor={'transparent'}
                      height={24}
                      width={24}
                    ></GamifyIcon>
                  </Box>
                  <Typography
                    style={{
                      fontSize: 16,
                      color: activeTab.includes(sideBarTab.val)
                        ? '#FF6A00'
                        : '#7C7C7C',
                      fontWeight: activeTab.includes(sideBarTab.val)
                        ? 600
                        : 400,
                    }}
                  >
                    {sideBarTab.label}
                  </Typography>
                </Box>
              );
            })}
          </>
        </Box>
      </Box>
      <Box
        style={{
          width: '100%',
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}
