import React, {
  useState,
  /* useEffect, */ SetStateAction,
  Dispatch,
} from 'react';
// import { API } from 'aws-amplify';
// import * as mutations from '../../../graphql/mutations';
// import * as utilities from '../../common/Utilities';
import { Backdrop, Box } from '@mui/material';
import DynamicConfirmModal from '../../../common/DynamicConfirmModal';
import { GamifyToast } from '../../../common/CustomToasts';
import Charges from '../paymentsTabs/Charges.view';

interface ChargeModalProps {
  deal: any;
  showChargeModal: boolean;
  setShowChargeModal: Dispatch<SetStateAction<boolean>>;
}

export default function ChargeModal(props: ChargeModalProps) {
  // NOTE: props that may be needed until SelectStage is redesigned: showAll, activeOption, allOption, minSequence, ignoreNoDealStages
  // NOTE: props that may be needed until NewSelectCircleOrPerson is redesigned: showAll, pickUsers, pickCircles, hideAssigned, allOption, allowNone
  const { deal, showChargeModal, setShowChargeModal } = props;

  const [isSaving, setIsSaving] = useState(false);

  const [showDynamicConfirmModal, setShowDynamicConfirmModal] =
    useState<boolean>(false);

  const handleCancelChangeClick = () => {
    // console.log('CANCEL CHANGE');
    setShowChargeModal(false);
  };

  return (
    <>
      <Backdrop
        sx={{ zIndex: 100002 }}
        open={showChargeModal}
        onClick={(e) => {
          if (
            // @ts-ignore
            !e.target.closest('.charge-modal') &&
            // @ts-ignore
            !e.target.closest('.charges-tab') &&
            // @ts-ignore
            !e.target.closest('.cc-form') &&
            // @ts-ignore
            !e.target.closest('.ach-form') &&
            // @ts-ignore
            !e.target.closest('.MuiInputBase-root') &&
            // @ts-ignore
            !e.target.closest('.charge-submit-button')
          ) {
            handleCancelChangeClick();
          }
        }}
      >
        {showChargeModal && (
          <Box
            className={'charge-modal'}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              backgroundColor: 'white',
              width: 624,
              maxHeight: '70vh',
              padding: '24px 0px 32px 0px',
              borderRadius: 8,
              overflowY: 'auto',
            }}
          >
            <Charges
              deal={deal}
              setShowChargeModal={setShowChargeModal}
            ></Charges>
          </Box>
        )}
      </Backdrop>
      <DynamicConfirmModal
        showDynamicConfirmModal={showDynamicConfirmModal}
        setShowDynamicConfirmModal={setShowDynamicConfirmModal}
        zIndex={100004}
        leftAlignText={true}
        title={'Do you want to process the following charge?'}
        subtitle={`Charge $___ to this account?`}
        onConfirm={async () => {
          setIsSaving(true);

          // TO DO: Make function to charge the inputted card or ACH
          //   await saveDealOwners(dealOwners, (successful) => {
          //     if (successful) {
          //       setIsSaving(false);
          //       setShowDynamicConfirmModal(false);
          //       // setDealOptionsOpen(false);
          //       // setAnchorEl(null);

          //       // Update deal owners' original values with newly updated values
          //       setDealOwners({
          //         ...dealOwners,
          //         originalStageID: dealOwners.currentStageID,
          //         originalSetterID: dealOwners.setterID,
          //         originalCloserID: dealOwners.closerID,
          //       });
          //     }
          //   });

          //   // Refetch only triggers for the selected deal on the DealsKanban, handleDealStageChange (in saveDealOwners) handles refetching for the kanban itself
          //   setTimeout(() => {
          //     onTriggerRefetch();
          //   }, 750);

          GamifyToast.success('Charge completed successfully');
        }}
        onCancel={() => {
          // Will want the ChargeModal to open again after cancelling from DynamicConfirmModal
          setShowDynamicConfirmModal(false);
          setShowChargeModal(true);
        }}
        isSaving={isSaving}
      ></DynamicConfirmModal>
    </>
  );
}
