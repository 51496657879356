import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import DealRepository from '../../../repository/DealRepository';
import { useParams } from 'react-router-dom';
import SelectedDealDisplay from './SelectedDealDisplay';
import { NoResultsIcon } from '../../../components/GamifyIcon';

export default function DealItem({ props, stateProps }) {
  const [lastDealID, setLastDealID] = useState(null);
  // TO DO: may want to rename from params for now? params are currently used in DealsList and those are passed to DealItem as props.
  const params = props;
  const {
    isOnKanban,
    payEngineStatus,
    setAddDealOpen,
    setSortOpen,
    // selectedDealOptionsOpen,
    // setSelectedDealOptionsOpen,
    selectedDealMetaDataOpen,
    setSelectedDealMetaDataOpen,
    handleOpenOwnerDealStageModal,
    handleOpenChargeModal,
    handleDeleteDeal,
    handleRefetchDeals,
    triggerSelectedDealRefetch,
    onTriggerSelectedDealRefetch,
    userId,
    categoryId,
  } = stateProps;
  // console.log('DealItem props: ', props);
  // console.log('DealItem stateProps: ', stateProps);
  console.log('useParams: ', useParams());

  // console.log('using useParams()');
  // params = useParams();
  // console.log('DealItem params: ', params);
  const [deal, setDeal] = useState([]);
  const [isDealLoading, setIsDealLoading] = useState(true);

  async function getSelectedDealData(dealId, searchUserId) {
    try {
      const _deal = await DealRepository.fetchDeal(dealId, searchUserId);
      // console.log('_deal: ', _deal);
      // setDeal(_deal);
      return _deal;
    } catch (error) {
      console.error('Error fetching deal: ', error);
    }
  }

  useEffect(() => {
    async function prepareSelectedDeal() {
      // console.log('Deal Item params.dealId = ' + params.dealId);
      // console.log('Deal Item lastDealID = ' + lastDealID);
      // if (params.dealId !== lastDealID) {
      const tempDeal = await getSelectedDealData(
        params.dealId,
        params.searchUserId
      );
      setDeal(tempDeal);
      setLastDealID(params.dealId);
      setIsDealLoading(false);
      // }
    }
    prepareSelectedDeal();
  }, [
    triggerSelectedDealRefetch,
    params.dealId,
    params.searchUserId,
    lastDealID,
  ]);

  // console.log('!!!DEAL MAIN DISPLAY: ', deal?.mainDisplay);

  return (
    <Box
      className={'deal-selected-item'}
      // sx={{
      //   border: '1px solid #827ffc',
      //   boxShadow: '0px 3px 8px #827ffc',
      //   padding: 1,
      //   borderRadius: '20px',
      //   width: '100%',
      //   height: 'auto',
      //   marginTop: 1,
      //   marginLeft: 2,
      //   backgroundColor: 'white',
      //   '&:hover': {
      //     backgroundColor: '#827ffc',
      //     textColor: 'white',
      //     opacity: [0.9, 0.8, 0.7],
      //   },
      // }}
    >
      {isDealLoading ? (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <CircularProgress sx={{ color: 'black' }}></CircularProgress>
        </Box>
      ) : deal ? (
        <Box
          sx={{
            marginLeft: 2,
            marginTop: 1,
            marginBottom: 2,
          }}
        >
          {deal.mainDisplay && (
            <SelectedDealDisplay
              props={{
                deal: deal.deal,
                isOnKanban,
                payEngineStatus,
                ...deal.mainDisplay,
                appointments: deal.appointments,
                comments: deal.comments,
                metaData: deal.dealMetaData,
                setAddDealOpen,
                setSortOpen,
                // selectedDealOptionsOpen,
                // setSelectedDealOptionsOpen,
                selectedDealMetaDataOpen,
                setSelectedDealMetaDataOpen,
                // setShowEditDealFormModal,
                handleOpenOwnerDealStageModal,
                handleOpenChargeModal,
                handleDeleteDeal,
                handleRefetchDeals,
                onTriggerSelectedDealRefetch,
                // getSelectedDealData,
                // params,
                userId,
                dealId: deal.id,
                categoryId,
                stageId: deal.currentStageID,
              }}
            ></SelectedDealDisplay>
          )}
        </Box>
      ) : (
        <Box
          style={{
            width: '100%',
            height: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '35vh',
          }}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#FF6A0026',
              height: 70,
              minHeight: 70,
              width: 70,
              borderRadius: 1000,
              marginBottom: 24,
            }}
          >
            <NoResultsIcon></NoResultsIcon>
          </Box>
          <Typography
            style={{
              color: '#696975',
              fontSize: 18,
              fontWeight: 600,
              letterSpacing: '-0.02em',
              textAlign: 'left',
            }}
          >
            No deal found
          </Typography>
          <Typography
            style={{
              color: '#696975',
              fontSize: 14,
              fontWeight: 400,
              letterSpacing: '-0.02em',
              textAlign: 'left',
            }}
          >
            Please try with another ID
          </Typography>
        </Box>
      )}
    </Box>
  );
}
