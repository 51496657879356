import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
// import IndexHome from './home/Index';
import IndexCampaigns from './campaigns/Index';
import NewCampaign from './campaigns/New';
import DealsList from './deals/list/DealsList.view';
import DealFormPage from './deals/form/DealFormPage';
// import Profile from './profile/Index';
import ProfileStatsPage from './profile/ProfileStatsPage';
import ProfileAboutPage from './profile/ProfileAboutPage';
import Scorecard from './scorecard/Scorecard';
import NotFound from './NotFound';
import KPILeaderboard from './kpi-leaderboard';
// import Dashboards from './dashboards/oldDashboard/Index';
import Dashboard from './dashboards/Dashboard.view';
import SelectChart from './charts/SelectChart';
import NewReward from './rewards/NewReward';
import UsersScreen from './users/UsersScreen';
import OrgsList from './config/OrgsList';
import OrgConfigPage from './config/OrgConfigPage';
import Games from './games';
import DevComp from './config/DevComp';
import TrainingHomePage from './training/TrainingHomePage';
import TrainingCategoryPage from './training/TrainingCategoryPage';
import TrainingVideoPage from './training/TrainingVideoPage';
import Feed from './feed';
import Comments from './feed/Comments';
import NewGame from './games/NewGame';
import NewIncentive from './games/NewIncentive';
import RewardsList from './rewards/RewardsList';
import TeamsScreen from './teams/TeamsScreen';
import TeamPage from './teams/TeamPage';
import TeamFormPage from './teams/TeamFormPage';
import RewardDetails from './rewards/RewardDetails';
import ReportsScreen from './reports/ReportsScreen';
import DataImports from './data-imports/DataImports';
import UserFormPage from './users/UserFormPage';
import PerformanceSideBar from './performance/PerformanceSideBar.view';
import ProductsScreen from './products/ProductsScreen';
// import CreateProductsScreen from './products/CreateProductsScreen/CreateProductsScreen.view';
import DealsKanban from './deals/kanban/DealsKanban.view';
import PipelineScreen from './pipeline/PipelineScreen.view';
import PaymentsSideBar from './payments/PaymentsSideBar.view';
import Transactions from './payments/paymentsTabs/Transactions.view';
import BatchSettlements from './payments/paymentsTabs/BatchSettlements.view';
import Payouts from './payments/paymentsTabs/Payouts.view';
import MerchantStatements from './payments/paymentsTabs/MerchantStatements.view';
import MerchantReports from './payments/paymentsTabs/MerchantReports';
import { SettingsPage } from './settings/SettingsPage';

export default function RouteConfiguration() {
  return (
    <BrowserRouter>
      <RouteWithBG />
    </BrowserRouter>
  );
}

function RouteWithBG() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="/feed" />} />

          <Route path="performance" element={<PerformanceSideBar />}>
            <Route path="leaderboard" element={<KPILeaderboard />} />
            <Route path="games" element={<Games />} />
            <Route path="games/new-game" element={<NewGame />} />
            <Route path="games/new-incentive" element={<NewIncentive />} />
            <Route path="rewards" element={<RewardsList />} />
            <Route path="rewards/new" element={<NewReward />} />
            <Route path="rewards/details" element={<RewardDetails />} />
            <Route path="reports" element={<ReportsScreen />} />
          </Route>

          {/* <Route path="profile/:userId" element={<Profile />} /> */}
          {/* <Route path="profile/:userId" element={<ProfileStatsPage />} /> */}
          <Route path="scorecard/:userId" element={<Scorecard />} />
          <Route path="campaigns" element={<IndexCampaigns />} />
          <Route
            path="charts"
            // @ts-ignore
            element={<SelectChart userID={global?.me?.id} />}
          />
          <Route path="training" element={<TrainingHomePage />} />
          <Route path="training/:category" element={<TrainingCategoryPage />} />
          <Route
            path="training/:category/:lessonId"
            element={<TrainingVideoPage />}
          />
          {/* <Route path="dashboards/:dashboardId" element={<Dashboards />} />
          <Route path="dashboards" element={<Dashboards />} /> */}
          <Route path="devtool" element={<DevComp />} />
          {/* <Route
            path="pipeline/:userId/:categoryId/:stageId"
            element={<DealsList />}
          /> */}
          {/* <Route path="kanban" element={<DealsKanban />} />
          <Route path="pipeline" element={<DealsList />} />
          <Route path="pipeline/:dealId" element={<DealsList />} />
          <Route path="pipeline/new" element={<DealFormPage />} />
          <Route path="pipeline/edit/:dealId" element={<DealFormPage />} /> */}
          <Route path="pipeline" element={<PipelineScreen />}>
            <Route path="board" element={<DealsKanban />} />
            <Route path="board/:dealId" element={<DealsKanban />} />
            <Route path="list" element={<DealsList />} />
            <Route path="list/:dealId" element={<DealsList />} />
            <Route path="new" element={<DealFormPage />} />
            <Route path="edit/:dealId" element={<DealFormPage />} />
          </Route>

          <Route path="feed" element={<Feed />} />
          <Route path="feed/comments" element={<Comments />} />
          <Route path="campaigns/new" element={<NewCampaign />} />
          <Route path="people" element={<UsersScreen />} />
          <Route path="people/new" element={<UserFormPage />} />
          <Route path="people/edit/:userId" element={<UserFormPage />} />
          <Route path="people/stats/:userId" element={<ProfileStatsPage />} />
          <Route path="people/about/:userId" element={<ProfileAboutPage />} />
          <Route path="teams" element={<TeamsScreen />} />
          <Route path="teams/:teamId" element={<TeamPage />} />
          <Route path="teams/new" element={<TeamFormPage />} />
          <Route path="teams/edit/:teamId" element={<TeamFormPage />} />
          <Route path="products" element={<ProductsScreen />} />
          {/* <Route
            path="products/edit/:productId"
            element={<CreateProductsScreen />}
          />
          <Route path="products/new" element={<CreateProductsScreen />} />
          <Route
            path="products/edit/:productId"
            element={<CreateProductsScreen />}
          /> */}
          <Route path="dashboard" element={<Dashboard />} />

          <Route path="payments" element={<PaymentsSideBar />}>
            <Route path="transactions" element={<Transactions />} />
            <Route path="batch-settlements" element={<BatchSettlements />} />
            <Route path="payouts" element={<Payouts />} />
            <Route
              path="merchant-statements"
              element={<MerchantStatements />}
            />
            <Route path="merchant-reports" element={<MerchantReports />} />
          </Route>

          <Route path="admin-config" element={<OrgsList />} />
          <Route path="admin-config/:orgID" element={<OrgConfigPage />} />
          <Route path="imports" element={<DataImports />} />
          <Route
            path="/settings"
            element={<Navigate to="/settings/roles" replace />}
          />
          <Route
            path="/settings"
            element={<Navigate to="/settings/roles" replace />}
          />
          <Route path="settings/*" element={<SettingsPage />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}
