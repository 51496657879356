import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../../graphql/mutations';
import { loadPayengine } from 'payengine';
import { PE_CONFIG } from '../utils/config';
import { Box } from '@mui/material';
import { GamifyToast } from '../../../common/CustomToasts';

export default function Transactions() {
  // @ts-ignore
  const MERCHANT_ID = global.appSettings?.payEngineMerchantId;
  const [hmacHash, setHmacHash] = useState<string>('');
  console.log('PE Transactions hmacHash: ', hmacHash);

  // Get an HMAC hash using the PayEngine secret key using the createPayEngineHash lambda
  const generateHMAC = async (merchantID: string) => {
    try {
      const res = await API.graphql(
        graphqlOperation(mutations.createPayEngineHash, {
          request: JSON.stringify({
            merchantID,
          }),
        })
      );
      // @ts-ignore
      const parsedData = JSON.parse(res.data.createPayEngineHash);
      console.log('hmacHash from lambda: ', parsedData);
      const hmac = parsedData.hmac || [];
      return hmac;
    } catch (err) {
      console.error(err);

      GamifyToast.error(`There was an error verifying PayEngine credentials.`);
    }
  };

  useEffect(() => {
    async function setUpHMAC(merchantID: string) {
      // Generate HMAC for the merchant ID
      const hmac = await generateHMAC(merchantID);

      // Set the generated HMAC state variable
      setHmacHash(hmac);
    }

    setUpHMAC(MERCHANT_ID);

    // Load Payengine on first render only
    loadPayengine({
      publicKey: PE_CONFIG.publicKey,
    }).then((pe) => {
      console.log(pe);
    });
  }, []);

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'fit-content',
        height: '100%',
      }}
    >
      {/* @ts-ignore */}
      <pay-engine
        id={'transactions'}
        merchant-id={MERCHANT_ID}
        hash={hmacHash}
        type={'transactions'}
      >
        {/* @ts-ignore */}
      </pay-engine>
    </Box>
  );
}
