import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Avatar, Box, Button, CardMedia, Typography } from '@mui/material';
import { EllipsisIcon } from '../../components/GamifyIcon';
import TeamOptionsMenu from './TeamOptionsMenu';
import RoleModal from './RoleModal';
import { usePermissions } from '../../context/PermissionsContext/PermissionsContext';
import { PermissionKeys } from '../../context/PermissionsContext/permissionTypes';

export default function TeamCard({
  user,
  team,
  setIsTeamListLoading,
  // rolePositionObj,
  // setRolePositionObj,
  onRemoveTeamFromState,
  onRemoveMemberFromTeam,
  //   setCurrentTeam,
  //   setDisplayDetails,
  //   setTeamChecked,
  isOnUserForm,
}) {
  const navigate = useNavigate();

  const { hasPermission, checkPermission } = usePermissions();

  const showChangeRole = checkPermission(
    PermissionKeys.TeamsPeople_ChangePersonsRole
  );
  const showRemoveFromTeam = hasPermission(
    PermissionKeys.TeamsPeople_RemoveSomeoneFromTeam,
    [user.id]
  );

  const showMenu = showChangeRole || showRemoveFromTeam;

  // const [teamOptionsOpen, setTeamOptionsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rolePositionObj, setRolePositionObj] = useState({
    original: user?.orgs?.items[0]?.roleID,
    form: user?.orgs?.items[0]?.roleID,
  });
  const [showRoleModal, setShowRoleModal] = useState(false);

  // console.log('TeamCard user: ', user);
  // console.log('TeamCard team: ', team);
  // console.log(
  //   'TeamCard onTriggerProfileDetailsRefetch: ',
  //   onTriggerProfileDetailsRefetch
  // );

  // NOTE: Due to TypeScript Interfaces, team is not extensible... for assigning team.id for TeamOptionsMenu, a deep copy is needed...
  const teamDeepCopy = { ...team };

  // TO DO: Replace onTriggerProfileDetailsRefetch with a refetch for refetching the userRolePosition object here
  useEffect(() => {
    function prepareUserRolePosition() {
      teamDeepCopy.id = team.circle ? team.circle.id : team.id;
    }
    prepareUserRolePosition();
  }, []);

  // const handleClickOffTeamOptionMenu = () => {
  //   setTeamOptionsOpen(false);
  // };

  return (
    <>
      {/* TO DO: update RoleModal to account for no user during user creation once role/position are team-specifc, not user-specific */}
      {!isOnUserForm && (
        <RoleModal
          user={user}
          roleOnly={true}
          rolePositionObj={rolePositionObj}
          setRolePositionObj={setRolePositionObj}
          showRoleModal={showRoleModal}
          setShowRoleModal={setShowRoleModal}
        ></RoleModal>
      )}
      {/* NOTE: This background was only needed for custom built options menu, CustomMenu and CustomMenuItem components do not need this */}
      {/* {teamOptionsOpen && (
        <Box
          onClick={handleClickOffTeamOptionMenu}
          className="option-menu-container-background"
        ></Box>
      )} */}
      <Box
        onClick={(e) => {
          // Do not navigate to any profile pages from the UserForm
          if (isOnUserForm) return;
          // Check if the click is not on the teams-option-button
          if (!e.target.closest('.teams-option-button')) {
            navigate(`/teams/${team.circleID}`, {
              state: { profileAboutPage: true, userId: user.id },
            });
          }
        }}
        sx={{ cursor: isOnUserForm ? 'default' : 'pointer' }}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'white',
          minWidth: 'fit-content',
          padding: 15,
          border: isOnUserForm ? 0 : '1px solid #D0D2D8',
          borderRadius: 8,
          marginBottom: 12,
        }}
      >
        <Box className={'teams-list-item-left-container'}>
          {/* Display team avatar if it has one, otherwise display initials */}
          {/* Account for image type to account for potential gifs */}
          {!isOnUserForm ? (
            team?.circle?.avatarFile ? (
              team?.circle?.imageType === 'picture' ||
              team?.circle?.imageType.split('/')[0] === 'image' ? (
                <Avatar
                  src={team?.circle?.avatarFile}
                  sx={{ margin: '5px 12px 5px 5px' }}
                />
              ) : (
                <CardMedia
                  image={team?.circle?.imageName}
                  component="img"
                  sx={{
                    backgroundColor: 'black',
                    borderRadius: 1000,
                    height: 40,
                    width: 40,
                    objectFit: 'contain',
                    margin: '5px 12px 5px 5px',
                  }}
                />
              )
            ) : (
              <Avatar
                sx={{ backgroundColor: '#868686', margin: '5px 12px 5px 5px' }}
              >
                {team?.circle?.name[0]}
              </Avatar>
            )
          ) : team?.avatarFile ? (
            team?.imageType === 'picture' ||
            team?.imageType.split('/')[0] === 'image' ? (
              <Avatar
                src={team?.avatarFile}
                sx={{ margin: '5px 12px 5px 5px' }}
              />
            ) : (
              <CardMedia
                image={team?.imageName}
                component="img"
                sx={{
                  backgroundColor: 'black',
                  borderRadius: 1000,
                  height: 40,
                  width: 40,
                  objectFit: 'contain',
                  margin: '5px 12px 5px 5px',
                }}
              />
            )
          ) : (
            <Avatar
              sx={{ backgroundColor: '#868686', margin: '5px 12px 5px 5px' }}
            >
              {team?.name[0]}
            </Avatar>
          )}
          <Box className={'teams-list-item-left-text-container'}>
            <Typography
              style={{ color: '#222428', fontSize: 16, fontWeight: 600 }}
            >
              {!isOnUserForm ? team.circle.name : team.name}
            </Typography>
            {!isOnUserForm && (
              <Typography
                style={{ color: '#868686', fontSize: 14, fontWeight: 500 }}
              >
                {team.memberType[0].toUpperCase() + team.memberType.slice(1)}
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          style={{
            position: 'relative',
            height: 45,
            display: 'flex',
            alignItems: isOnUserForm ? 'center' : 'flex-start',
          }}
        >
          {/* TO DO: need color palette for approved, error, requested, denied, delivered, returned, shipped, and backordered */}
          {!isOnUserForm && ['approved', 'accepted'].includes(team.status) && (
            <Box
              style={{
                backgroundColor: '#C2FCEE',
                padding: '4px 8px 4px 8px',
                borderRadius: '1000px',
              }}
            >
              <Typography
                style={{ color: '#017E6A', fontSize: 12, fontWeight: 700 }}
              >
                {team.status[0].toUpperCase() + team.status.slice(1)}
              </Typography>
            </Box>
          )}
          {showMenu && (
            <>
              <Button
                className={'teams-option-button'}
                onClick={(e) => {
                  e.stopPropagation();
                  // setTeamOptionsOpen(!teamOptionsOpen);
                  setAnchorEl(e.currentTarget);
                }}
                disableRipple={true}
              >
                <EllipsisIcon color={'#868686'}></EllipsisIcon>
              </Button>

              <TeamOptionsMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                team={teamDeepCopy}
                user={user}
                setIsTeamListLoading={setIsTeamListLoading}
                setShowRoleModal={setShowRoleModal}
                onRemoveTeamFromState={onRemoveTeamFromState}
                onRemoveMemberFromTeam={onRemoveMemberFromTeam}
                isOnUserForm={isOnUserForm}
              ></TeamOptionsMenu>
            </>
          )}
          {/* {teamOptionsOpen && (
            <>
              <TeamOptionsMenu
                setTeamOptionsOpen={setTeamOptionsOpen}
                team={team}
                user={user}
                setIsTeamListLoading={setIsTeamListLoading}
                setShowRoleModal={setShowRoleModal}
                onTriggerTeamRefetch={onTriggerTeamRefetch}
                onRemoveTeamFromState={onRemoveTeamFromState}
                onRemoveMemberFromTeam={onRemoveMemberFromTeam}
                isOnUserForm={isOnUserForm}
              ></TeamOptionsMenu>
            </>
          )} */}
        </Box>
      </Box>
    </>
  );
}
