import React, { useState, useEffect } from 'react';
import './Index.css';
import { orgMemberQuery } from './rewardQueries';
import { API } from 'aws-amplify';
import moment from 'moment';
import * as utils from '../../common/Utilities';
import gql from 'graphql-tag';
import getLocalDateTime from '../games/games-helpers/getLocalDateTime';
import {
  GridContainer,
  HeaderContainer,
  NewRewardButton,
  NoImageText,
  NoRewardsText,
  Title,
  ViewContainer,
  AddModalContainer,
  AddModalIcon,
  AddModalItem,
  AddModalItemLeftSide,
  AddModalItemRightSide,
  AddModalItemTitle,
} from './Rewards.styles';
import CenteredProgress from '../../components/CenteredProgress';
import DisplayMedia from '../../components/DisplayMedia';
import { useNavigate } from 'react-router';
import { usePermissions } from '../../context/PermissionsContext/PermissionsContext';
import { PermissionKeys } from '../../context/PermissionsContext/permissionTypes';

export default function RewardsList() {
  const [myRewards, setMyRewards] = useState([]);
  const startBefore = new Date();
  const endAfter = new Date();
  const [loading, setLoading] = useState(true);
  const [openAddModal, setOpenAddModal] = useState(false);
  const navigate = useNavigate();

  const { checkPermission } = usePermissions();
  const showAdd = checkPermission(PermissionKeys.GamesRewards_CreateReward);

  const addModalChoices = [
    {
      label: 'New Reward',
      icon: '/images/new-reward.svg',
      onClick: () => navigate('/performance/rewards/new'),
      disabled: false,
    },
    // {
    //   label: 'New from Catalog',
    //   icon: '/images/new-from-catalog.svg',
    //   onClick: () => navigate('/performance/games/new-incentive'),
    //   disabled: true,
    // },
    // {
    //   label: 'New Gift Card',
    //   icon: '/images/new-gift-card.svg',
    //   onClick: () => navigate('/performance/games/new-incentive'),
    //   disabled: true,
    // },
  ];

  async function fetchMyRewards() {
    try {
      const orgMemberQueryRes = await API.graphql({
        query: orgMemberQuery,
        variables: {
          userID: global.me.id,
          orgID: global.me.orgID,
          limit: 100000,
        },
      });

      const orgPoints =
        orgMemberQueryRes.data.OrgMemberByUser.items[0].orgPoints;
      const lifeTimeOrgPoints =
        orgMemberQueryRes.data.OrgMemberByUser.items[0].lifeTimeOrgPoints;

      const myQuery = gql`
        query MyQuery(
          $orgID: ID = "smarthaven"
          $today: String = "2024-05-07"
          $today: String = "2024-05-07"
          $nextToken: String = null
          $startBefore: String
        ) {
          listRewardsByOrgName(
            orgID: $orgID
            filter: {
              isDeleted: { eq: false }
              launchDate: { le: $startBefore }
              validTo: { ge: $today }
            }
            nextToken: $nextToken
          ) {
            items {
              approverType
              cost
              costType
              createdAt
              createdBy
              description
              giftcardDisclaimer
              giftcardValue
              id
              imageName
              imageType
              isActive
              isDeleted
              lastUpdatedBy
              launchDate
              limitCount
              location
              maxGiftcardAmount
              minGiftcardAmount
              name
              nextExpireFeedBlast
              nextLaunchFeedBlast
              orgID
              ownerID
              ownerType
              permissions
              personLimit
              remainingCount
              rewardType
              tags
              tangoAccountID
              updatedAt
              utid
              validFrom
              validTo
              value
              valueType
            }
            nextToken
          }
        }
      `;

      const ourCircles = [];
      let ourRewards = [];
      let _customRewardCount = 0;
      let nextToken = null;

      do {
        const listRewardsRes = await API.graphql({
          query: myQuery,
          variables: {
            orgID: global.me.orgID,
            startBefore,
            endAfter,
            // sortDirection,
            nextToken,
          },
        });
        console.log('!!!listRewardsRes: ', listRewardsRes);
        const rewards = listRewardsRes.data.listRewardsByOrgName.items;
        console.log('!!!rewards: ', rewards);

        for (const reward of rewards) {
          if (!reward.isDeleted) {
            const now = getLocalDateTime();
            const validFrom = new Date(reward.validFrom);
            const gmtValidToDate = new Date(reward.validTo);
            const validTo = moment(gmtValidToDate)
              .add(1, 'day')
              .subtract(1, 'second');

            reward.remaingDaysAvailable = utils.daysBetween(now, validTo);
            reward.daysBeforeAvailable = utils.daysBetween(now, validFrom);
            reward.secondsBeforeStart = utils.secondsBetween(now, validFrom);
            reward.remainingSecondsInMission = utils.secondsBetween(
              now,
              validTo
            );

            reward.myCirclePoints = orgPoints;
            reward.purchaseCount = 0;
            reward.isVisible = true;
            if (reward.isVisible) {
              ourRewards[ourRewards.length] = reward;
            }
            if (reward.utid !== '') {
              _customRewardCount += 1;
            }
          }
          ourRewards = ourRewards.filter(
            (reward) => reward.remaingDaysAvailable > 0
          );

          ourCircles[0] = {
            name: '',
            circlePoints: orgPoints,
            id: '',
            isActive: true,
            lifetimeCirclePoints: lifeTimeOrgPoints,
            memberType: '',
            status: '',
            rewards: ourRewards,
          };
        }

        nextToken = listRewardsRes.data.listRewardsByOrgName.nextToken;
      } while (nextToken !== null);

      console.log('!!ourCircles: ', ourCircles);
      if (_customRewardCount === 1234) {
        console.log(_customRewardCount);
      }

      setMyRewards(ourCircles);
    } catch (err) {
      console.error('fetchMyRewards(): error fetching my Catalog' + err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchMyRewards();
  }, []);

  if (loading) {
    return (
      <div>
        <CenteredProgress />
      </div>
    );
  }

  return (
    <ViewContainer>
      <HeaderContainer>
        <Title>Rewards</Title>
        {showAdd && (
          <NewRewardButton
            onClick={() => {
              setOpenAddModal(!openAddModal);
            }}
          >
            <img src="/images/new-reward-plus.svg" width={22} height={22} />
            <div>New reward</div>
          </NewRewardButton>
        )}
        {openAddModal && (
          <AddModalContainer>
            {addModalChoices.map((choice) => {
              return (
                <AddModalItem
                  key={choice.label}
                  onClick={() => {
                    if (choice.disabled) return;

                    choice.onClick();
                  }}
                >
                  <AddModalItemLeftSide>
                    <AddModalItemTitle disabled={choice.disabled}>
                      {choice.label}
                    </AddModalItemTitle>
                  </AddModalItemLeftSide>
                  <AddModalItemRightSide>
                    {choice.disabled && (
                      <div
                        style={{
                          color: '#FF3535',
                          fontWeight: 600,
                          fontSize: 12,
                          display: 'flex',
                          alignSelf: 'center',
                        }}
                      >
                        Coming soon!
                      </div>
                    )}
                    {!choice.disabled && (
                      <AddModalIcon>
                        <img
                          width={20}
                          height={20}
                          src={choice.icon}
                          alt={choice.label}
                        />
                      </AddModalIcon>
                    )}
                  </AddModalItemRightSide>
                </AddModalItem>
              );
            })}
          </AddModalContainer>
        )}
      </HeaderContainer>
      {loading ? (
        <div>
          <CenteredProgress />
        </div>
      ) : null}
      {myRewards.length === 0 && !loading ? (
        <NoRewardsText>You have no rewards</NoRewardsText>
      ) : null}
      {myRewards.length !== 0 && !loading ? (
        <GridContainer>
          {myRewards.map((circle) =>
            circle.rewards.map((reward) => {
              if (reward.remaingDaysAvailable < 0) return null;

              const shouldLock = reward.ownerType === 'mission';

              return (
                <div
                  key={reward.id}
                  style={{
                    border: '1px solid #D0D2D8',
                    borderRadius: 6,
                    cursor: shouldLock ? 'default' : 'pointer',
                    position: 'relative',
                  }}
                  onClick={() =>
                    navigate('/performance/rewards/details', { state: reward })
                  }
                >
                  {/* {global.me.isAdmin && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 4,
                        right: 4,
                        cursor: 'pointer',
                      }}
                    >
                      <img
                        src="/images/ellipse-reward.svg"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        width={22}
                        height={22}
                      />
                    </div>
                  )} */}

                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {reward.imageName !== '' ? (
                      <DisplayMedia
                        imageName={reward.imageName}
                        imageType={reward.imageType}
                        objectFit="cover"
                      />
                    ) : (
                      <NoImageText>No Image Available</NoImageText>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '12px 12px 2px 12px',
                      marginTop: 4,
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ fontWeight: 700, fontSize: 14 }}>
                      {reward.name}
                    </div>
                    {shouldLock && (
                      <div
                        style={{
                          backgroundColor: '#F0F0F3',
                          width: 'fit-content',
                          height: 'fit-content',
                          padding: '4px 8px',
                          borderRadius: 20,
                          display: 'flex',
                          alignItems: 'center',
                          gap: 6,
                        }}
                      >
                        <img src="/images/lock.svg" />
                        <div style={{ fontWeight: 700, fontSize: 12 }}>
                          Locked
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      fontSize: 14,
                      color: '#868686',
                      fontWeight: 500,
                      paddingLeft: 12,
                      paddingBottom: 12,
                    }}
                  >
                    {formatPoints(reward.cost)}
                  </div>
                </div>
              );
            })
          )}
        </GridContainer>
      ) : null}
    </ViewContainer>
  );
}

function formatPoints(number) {
  if (number <= 0) return '';

  if (number === 1) {
    return number + ' pt';
  } else {
    return number + ' pts';
  }
}
