import moment, * as Moment from 'moment/moment';
import treatAsUTC from './utils/treatAsUTC';
import { Logger } from 'aws-amplify';

export const LOG = new Logger('gamifywebLog', 'DEBUG');

export const beginningOfTime = '1900-01-01';
export const endOfTime = '3000-01-01';

export default {
  default_date_format: 'MM/DD/YYYY',
  beginningOfTime: '1900-01-01',
  endOfTime: '3000-01-01',
  fontSizes: { xs: 10, sm: 15, md: 20, lg: 30 },

  elapsedTimeSince: (startDateStr, endDateStr) => {
    const millisecondsPerSecond = 1000;
    const secondsInMinute = 60;
    const secondsInHour = 60 * secondsInMinute;
    const secondsInDay = 24 * secondsInHour;
    const secondsInWeek = 7 * secondsInDay;
    // eslint-disable-next-line new-cap
    const startDate = new Moment(startDateStr);
    // eslint-disable-next-line new-cap
    const endDate = new Moment(endDateStr);

    const seconds = Math.ceil(
      (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerSecond
    );
    // //LOG.debug ("seconds: ", seconds);
    if (seconds < secondsInMinute) {
      return seconds + 's';
    }
    if (seconds < secondsInHour) {
      return Math.trunc(seconds / secondsInMinute) + 'm';
    }
    if (seconds < secondsInDay) {
      return Math.trunc(seconds / secondsInHour) + 'h';
    }
    if (seconds < secondsInWeek) {
      return Math.trunc(seconds / secondsInDay) + 'd';
    }
    return Math.trunc(seconds / secondsInWeek) + 'w';
  },
  getLocalDateTime: (date) => {
    const result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
  },
  endOfToday: (dateStr) => {
    // eslint-disable-next-line new-cap
    const date = new Moment.utc(dateStr);
    return date.format('YYYY-MM-DD') + '23:59:59';
  },
  formatDate: (dateStr, dateFormat) => {
    // eslint-disable-next-line new-cap
    const date = new Moment.utc(dateStr);
    let ourDateFormat = dateFormat;
    if (
      ourDateFormat === undefined ||
      ourDateFormat === '' ||
      ourDateFormat === ''
    ) {
      ourDateFormat = 'MM/DD/YYYY';
    }
    return date.format(ourDateFormat);
  },
  getLocalDateTimeFormattedFromDate: (format, fromDate) => {
    const curDate = Moment(fromDate);
    return curDate.format(format);
  },
  formattedLocalShortDateYear(dateStr) {
    // eslint-disable-next-line new-cap
    const date = new Moment.utc(dateStr);
    return date.local().format('M/D/YY H:mm');
  },
  graphqlDateFormat: (dateStr) => {
    // eslint-disable-next-line new-cap
    const date = new Moment.utc(dateStr);
    return date.format('YYYY-MM-DD');
  },
  localToIsoDateFormat: (dateString) => {
    if (dateString === undefined || dateString === null || dateString === '') {
      return '';
    }

    // eslint-disable-next-line new-cap
    const date = new Moment(dateString);
    const dateComponent = date.utc().format('YYYY-MM-DD');
    const timeComponent = date.utc().format('HH:MM:ss');

    if (dateComponent === 'Invalid date') {
      return `${dateString}T00:00:00.000Z`;
    }
    const dateTimeString = `${dateComponent}T${timeComponent}.000Z`;
    if (dateComponent === 'Invalid date' || timeComponent === 'Invalid date') {
      return `${dateString}T00:00:00.000Z`;
    }
    return dateTimeString;
  },
};

export function getTZ() {
  const date = new Date();
  return date.getTimezoneOffset();
}

export function getCurrentUTCDate() {
  const date = new Date();
  // console.log("!!!getCurrentUTCDate: ", date);
  const utcDate = date.getUTCDate();
  // const utcDate =  new Date(
  //   date.getUTCFullYear(),
  //   date.getUTCMonth(),
  //   date.getUTCDate(),
  //   date.getUTCHours(),
  //   date.getUTCMinutes(),
  //   date.getUTCSeconds()
  // );
  console.log('!!!getCurrentUTCDate uTC: ', utcDate);

  return date;
}

export function newLocalToISODateFormat(dateStr) {
  // LOG.debug ("localToISODateFormat: ", dateStr);
  if (dateStr === 'undefined') {
    // LOG.debug ("returning BLANK");
    return '';
  }
  if (dateStr === '') {
    // LOG.debug ("returning BLANK");
    return '';
  }

  const date = new Moment(new Date());
  // console.log('!!!iso format: ', date.format());
  return date.format();

  // const dateVal = date.utc().format("YYYY-MM-DD");
  // const timeVal = date.utc().format("HH:MM:ss");

  // LOG.debug("TZ Offset: ", date.utc());

  // // const dateVal = formatDate (dateStr, "YYYY-MM-DD");
  // // const timeVal = formatDate ( dateStr, "HH:MM:ss");
  // if (dateVal === "Invalid date") {
  //   const dateTimeStr = dateStr + "T" + "00:00:00" + ".000Z";
  //   //LOG.debug ("dateTimeStr: ", dateTimeStr);
  //   return dateTimeStr;
  // }
  // const dateTimeStr = dateVal + "T" + timeVal + ".000Z";
  // //LOG.debug ("dateTimeStr: ", dateTimeStr);
  // if (dateVal === "Invalid date" || timeVal === "Invalid date") {
  //   const dateTimeStr = dateStr + "T" + "00:00:00" + ".000Z";
  //   // LOG.debug ("dateTimeStr: ", dateTimeStr);
  //   return dateTimeStr;
  // }
  // return dateTimeStr;
}

export function daysBetween(startDate, endDate) {
  /// /LOG.debug ("daysBetween: ", startDate + " - " + endDate);
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  return Math.ceil(
    (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay
  );
}

export function secondsBetween(startDate, endDate) {
  const millisecondsPerSecond = 1000;
  return Math.ceil(
    (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerSecond
  );
}

export function numberWithCommas(x) {
  if (isNaN(x)) {
    return '';
  }
  if (x == null) {
    return '';
  }
  return Math.round(x)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formattedLocalShortestDate(dateStr) {
  // eslint-disable-next-line new-cap
  const date = new Moment.utc(dateStr);
  return date.local().format('M/D/YY');
}

// For MM/DD/YYYY dates
export const dateRegExp =
  /^(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}$/;
// updated dateRegExp for 'YYYY-MM-DD' not 'MM/DD/YYYY' and include 2100 for MAX_DATE, used in TeamFormPage
export const newDateRegExp =
  /^(19\d{2}|20\d{2}|2100)-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

export const MAX_DATE = '2100-01-01';
export const MIN_DATE = '1900-01-01';

export const defaultIfEmpty = (str, def) => {
  if (str === '' || str === null) {
    return def;
  } else {
    return str;
  }
};

export const defaults = {
  displayLabels: true,
};

export function defaultDateFormat(dateStr) {
  if (dateStr === '') {
    return '';
  }
  const Date = new Moment(dateStr);
  return Date.format(global.appSettings.dateFormat);
}

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';

export function formatDate(dateStr, dateFormat) {
  // eslint-disable-next-line new-cap
  const Date = new Moment.utc(dateStr);
  let ourDateFormat = dateFormat;
  if (
    ourDateFormat === undefined ||
    ourDateFormat === '' ||
    ourDateFormat === ''
  ) {
    ourDateFormat = DEFAULT_DATE_FORMAT;
  }
  return Date.format(ourDateFormat);
}

export function graphqlDateFormat(dateStr) {
  return formatDate(dateStr, 'YYYY-MM-DD');
}

export const blankIfValue = (str, val) => {
  if (str === val) {
    return '';
  } else {
    if (str !== null) {
      return str;
    } else {
      return '';
    }
  }
};

export function defaultDateTimeFormat(dateStr) {
  if (dateStr === '') {
    return '';
  }
  const date = new Moment(dateStr);
  return date.format(global.appSettings.dateFormat + ' HH:mm');
}

export const dateTimeRegExp =
  /^\d{2}\/\d{2}\/\d{4}\s*(?:\d{2}:\d{2}(?::\d{2})?)?$/;

export function formattedLocalDate(dateStr) {
  // eslint-disable-next-line new-cap
  const date = new Moment.utc(dateStr);
  return date.local().format('MM/DD/YYYY');
}

export function formattedLocalDateTimeShort(dateStr) {
  // eslint-disable-next-line new-cap
  const date = new Moment.utc(dateStr);
  return date.local().format('MM/DD/YYYY HH:mm');
}

export function getLocalDateTime() {
  return treatAsUTC(new Date());
}

export function getLocalDate() {
  const curDate = moment(treatAsUTC(new Date()));
  return curDate.format('YYYY-MM-DD');
}

export function getPreviousLocalDate(days = 1) {
  const curDate = moment(treatAsUTC(new Date()));
  return curDate.subtract(days, 'days').format('YYYY-MM-DD');
}

export function getNextLocalDate(days = 1) {
  const curDate = moment(treatAsUTC(new Date()));
  return curDate.add(days, 'days').format('YYYY-MM-DD');
}

export function formattedMonthDay(dateStr) {
  // eslint-disable-next-line new-cap
  const date = new Moment.utc(dateStr);
  return date.format('MM/DD');
}

export function formattedLocalShortDate(dateStr) {
  // eslint-disable-next-line new-cap
  const date = new Moment.utc(dateStr);
  return date.local().format('M/D H:mm');
}

export function newFormattedLocalCustom(dateStr, format) {
  const date = new Moment(dateStr);
  // console.log("@@@dateStr: ", dateStr, date, date.format(format));

  return date.format(format);
}

// function that generates random string for image file names in XXXXXXXX-XXXX-XXXX-XXXXXXXXXXXX format
export function generateRandomImageFileNameString() {
  let res = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const sections = [8, 4, 4, 4, 12];

  for (let i = 0; i < sections.length; i++) {
    for (let j = 0; j < sections[i]; j++) {
      res += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    if (i < sections.length - 1) {
      res += '-';
    }
  }

  return res;
}

export function getInitials(string) {
  const trimmed = string.trim();
  if (trimmed === '') return '';
  const splitString = trimmed.split(' ');

  if (splitString.length === 0) {
    return string;
  } else if (splitString.length === 1) {
    return splitString[0][0].toUpperCase();
  } else {
    return splitString[0][0].toUpperCase() + splitString[1][0].toUpperCase();
  }
}

export function cleanPhoneNumber(string) {
  return string.replace(/[^\d]/g, '');
}
