import React, { useState, useEffect, useRef } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../../graphql/mutations';
import { loadPayengine } from 'payengine';
import { PE_CONFIG } from '../utils/config';
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';
import { GamifyToast } from '../../../common/CustomToasts';
import { GamifyIcon } from '../../../components/GamifyIcon';

interface ACHFormProps {
  deal: any;
  setShowChargeModal: any;
}

export default function ACHForm(props: ACHFormProps) {
  const { deal, setShowChargeModal } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [transactionAmount, setTransactionAmount] = useState<number>(0);
  const [transactionDescription, setTransactionDescription] =
    //   @ts-ignore
    useState<string>(global.me.orgID + ' services');
  const [receiptEmail, setReceiptEmail] = useState<string>('');

  //   NOTE: Needed to prevent stale state in handleSuccess function
  const transactionAmountRef = useRef<number>(transactionAmount);
  const transactionDescriptionRef = useRef<string>(transactionDescription);
  const receiptEmailRef = useRef<string>(receiptEmail);

  // @ts-ignore
  const MERCHANT_ID = global.appSettings?.payEngineMerchantId;
  const [hmacHash, setHmacHash] = useState<string>('');
  const payEngineRef = useRef<HTMLElement | null>(null);
  const [errorsArr, setErrorsArr] = useState<string[]>([]);

  const handleSubmit = () => {
    if (payEngineRef.current) {
      if (transactionAmount === 0) {
        GamifyToast.error(
          'You must input a transaction amount before submitting, please try again.'
        );
        return;
      }
      setIsLoading(true);

      // @ts-ignore
      payEngineRef.current.submit();
    } else {
      console.error('PayEngine component not found');
    }
  };

  const handleSuccess = async (e: any) => {
    if (errorsArr.length > 0) {
      setErrorsArr([]);
    }

    // console.log('Payment success:', e.detail);

    const chargeRcd = {
      dealID: deal.id,
      transactionAmount: transactionAmountRef.current,
      accountToken: e.detail?.token,
      description: transactionDescriptionRef.current,
      email: receiptEmailRef.current,
    };
    // console.log('Payment success chargeRcd: ', chargeRcd);
    await API.graphql(
      graphqlOperation(mutations.saveAuthToken, {
        request: JSON.stringify(e.detail),
      })
    );

    await API.graphql(
      graphqlOperation(mutations.chargeACH, {
        request: JSON.stringify(chargeRcd),
      })
    );

    GamifyToast.success('Payment submitted succesfully');
    setIsLoading(false);
    setShowChargeModal(false);
  };

  const handleError = (e: any) => {
    console.error('Payment error:', e.detail);

    const tempErrors = [];

    if (e.detail?.first_name) {
      for (const errorMessage of e.detail.first_name.errorMessages) {
        if (errorMessage === 'is required') {
          tempErrors.push('A first name is required');
        }
      }
    }

    if (e.detail?.last_name) {
      for (const errorMessage of e.detail.last_name.errorMessages) {
        if (errorMessage === 'is required') {
          tempErrors.push('A last name is required');
        }
      }
    }

    if (e.detail?.account_number) {
      for (const errorMessage of e.detail.account_number.errorMessages) {
        if (errorMessage === 'is required') {
          tempErrors.push('An account number is required');
        }

        if (errorMessage === "the value doesn't match a pattern") {
          tempErrors.push('Please enter a valid account number');
        }
      }
    }

    if (e.detail?.routing_number) {
      for (const errorMessage of e.detail.routing_number.errorMessages) {
        if (errorMessage === 'is required') {
          tempErrors.push('A routing number is required');
        }

        if (errorMessage === "the value doesn't match a pattern") {
          tempErrors.push('Please enter a valid routing number');
        }
      }
    }

    setErrorsArr(tempErrors);
    GamifyToast.error(
      'There was an error charging this card, please fix the listed errors and try again.'
    );
    setIsLoading(false);
  };

  // Get an HMAC hash using the PayEngine secret key using the createPayEngineHash lambda
  const generateHMAC = async (merchantID: string) => {
    try {
      const res = await API.graphql(
        graphqlOperation(mutations.createPayEngineHash, {
          request: JSON.stringify({
            merchantID,
          }),
        })
      );
      // @ts-ignore
      const parsedData = JSON.parse(res.data.createPayEngineHash);
      const hmac = parsedData.hmac || [];
      return hmac;
    } catch (err) {
      console.error(err);

      GamifyToast.error(`There was an error verifying PayEngine credentials.`);
    }
  };

  async function setUpHMAC(merchantID: string) {
    // Generate HMAC for the merchant ID
    const hmac = await generateHMAC(merchantID);

    // Set the generated HMAC state variable
    setHmacHash(hmac);
  }

  useEffect(() => {
    setUpHMAC(MERCHANT_ID);

    // Load Payengine on first render only
    loadPayengine({
      publicKey: PE_CONFIG.publicKey,
    }).then((pe) => {
      console.log(pe);
    });

    //   Set success and error listeners to the payEngineRef element (ACH form)
    const currentRef = payEngineRef.current;
    // console.log('PE Mounted. Adding event listener', currentRef);
    currentRef?.addEventListener('success', handleSuccess);
    currentRef?.addEventListener('error', handleError);

    return () => {
      // console.log('PE Unmounted. Clean up', currentRef);
      currentRef?.removeEventListener('success', handleSuccess);
      currentRef?.removeEventListener('error', handleError);
    };

    // if (payEngineRef.current) {
    //   payEngineRef.current.addEventListener('success', handleSuccess);
    //   payEngineRef.current.addEventListener('error', handleError);

    //   // Remove any existing listeners before adding to prevent duplication
    //   payEngineRef.current.removeEventListener('success', handleSuccess);
    //   payEngineRef.current.removeEventListener('error', handleError);

    //   // Cleanup listeners on unmount
    //   return () => {
    //     payEngineRef.current?.removeEventListener('success', handleSuccess);
    //     payEngineRef.current?.removeEventListener('error', handleError);
    //   };
    // }
  }, []);

  // NOTE: Update the state refs whenever they update so handleSuccess get the right values
  useEffect(() => {
    transactionAmountRef.current = transactionAmount;
  }, [transactionAmount]);

  useEffect(() => {
    transactionDescriptionRef.current = transactionDescription;
  }, [transactionDescription]);

  useEffect(() => {
    receiptEmailRef.current = receiptEmail;
  }, [receiptEmail]);

  const handleClearTransaction = () => {
    setTransactionAmount(0);
  };

  const handleClearDescription = () => {
    setTransactionDescription('');
  };

  const handleClearEmail = () => {
    setReceiptEmail('');
  };

  const cancelTransactionIconAdornment =
    transactionAmount > 0 ? (
      <Box
        sx={{ cursor: 'pointer' }}
        className={'clear-input-button'}
        onClick={handleClearTransaction}
      >
        <InputAdornment position="end">
          <GamifyIcon icon={'delete'}></GamifyIcon>
        </InputAdornment>
      </Box>
    ) : (
      <></>
    );

  const cancelDescriptionIconAdornment =
    transactionDescription.length > 0 ? (
      <Box
        sx={{ cursor: 'pointer' }}
        className={'clear-input-button'}
        onClick={handleClearDescription}
      >
        <InputAdornment position="end">
          <GamifyIcon icon={'delete'}></GamifyIcon>
        </InputAdornment>
      </Box>
    ) : (
      <></>
    );

  const cancelEmailIconAdornment =
    receiptEmail.length > 0 ? (
      <Box
        sx={{ cursor: 'pointer' }}
        className={'clear-input-button'}
        onClick={handleClearEmail}
      >
        <InputAdornment position="end">
          <GamifyIcon icon={'delete'}></GamifyIcon>
        </InputAdornment>
      </Box>
    ) : (
      <></>
    );

  // NOTE: Should move to a separate file to be used in both CreditCardForm and ACHForm
  const textInputSx = {
    width: '296px',
    height: '48px',
    border: '1px solid #D0D2D8',
    borderRadius: '4px !important',
    marginBottom: '11px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .MuiOutlinedInput-root': {
      // background: '#222428',
      backgroundColor: 'transparent',
      width: '100%',
    },

    '& .MuiOutlinedInput-input': {
      color: '#222428',
      fontFamily: 'Manrope, sans-serif',
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '-0.01em',
      textAlign: 'left',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiOutlinedInput-input::placeholder': {
      color: '#0B0B0C',
    },
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        marginTop: 24,
      }}
    >
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography style={{ paddingBottom: 8 }}>
          Transaction Amount ($ USD):
        </Typography>
        <TextField
          placeholder="$0.00"
          value={transactionAmount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
          onChange={(e) => {
            // This regex removes non-numeric characters
            const input = e.target.value.replace(/[^0-9]/g, '');

            if (input === '') {
              setTransactionAmount(0);
              return;
            }

            // Convert to integer
            const numericValue = parseInt(input, 10);

            // Adjust for cents (e.g., 123 -> $1.23)
            setTransactionAmount(numericValue / 100);
          }}
          InputProps={{
            endAdornment: cancelTransactionIconAdornment,
          }}
          sx={textInputSx}
        />

        <Typography style={{ paddingBottom: 8 }}>Description:</Typography>
        <TextField
          placeholder=""
          value={transactionDescription}
          onChange={(e) => {
            setTransactionDescription(e.target.value);
          }}
          InputProps={{
            endAdornment: cancelDescriptionIconAdornment,
          }}
          sx={textInputSx}
        />

        <Typography style={{ paddingBottom: 8 }}>Receipt Email:</Typography>
        <TextField
          placeholder={'receipt@email.com'}
          value={receiptEmail}
          onChange={(e) => {
            setReceiptEmail(e.target.value);
          }}
          InputProps={{
            endAdornment: cancelEmailIconAdornment,
          }}
          sx={textInputSx}
        />
      </Box>

      {/* @ts-ignore */}
      <pay-engine
        id={'ach-form'}
        ref={payEngineRef}
        merchant-id={MERCHANT_ID}
        hash={hmacHash}
        type={'bankaccountform'}
      >
        {/* @ts-ignore */}
      </pay-engine>

      {errorsArr.length > 0 && (
        <Box
          style={{
            display: 'felx',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography style={{ color: '#DA2424', textAlign: 'left' }}>
            Please fix the following errors and retry:
          </Typography>
          <List sx={{ listStyleType: 'disc', color: '#DA2424', pl: 4 }}>
            {errorsArr.map((error, i) => (
              <ListItem
                key={i}
                sx={{ display: 'list-item', paddingTop: 0, paddingBottom: 0 }}
              >
                <Typography style={{ color: '#DA2424' }}>{error}</Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      <Box
        style={{ display: 'flex', justifyContent: 'space-evenly', width: 296 }}
      >
        <Button
          onClick={() => {
            setTransactionAmount(0);
            setTransactionDescription('');
            setErrorsArr([]);
            setShowChargeModal(false);
          }}
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            backgroundColor: 'white',
            width: '124px',
            height: '48px',
            padding: '12px 16px 12px 16px',
            marginTop: '8px',
            borderRadius: '8px',
            border: '1px solid #D0D2D8',
          }}
          disableRipple={true}
        >
          <Typography
            style={{
              color: 'black',
              fontSize: '16px',
              fontWeight: 500,
            }}
          >
            Cancel
          </Typography>
        </Button>

        <Button
          onClick={handleSubmit}
          className={'charge-submit-button'}
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            backgroundColor: '#FF6A00',
            width: '124px',
            height: '48px',
            padding: '12px 16px 12px 16px',
            marginTop: '8px',
            borderRadius: '8px',
          }}
          disableRipple={true}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress
              style={{ color: 'white', height: 24, width: 24 }}
            ></CircularProgress>
          ) : (
            <Typography
              style={{
                color: 'white',
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              Submit
            </Typography>
          )}
        </Button>
      </Box>
    </Box>
  );
}
